import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import {
  erontrans, greenyard, nagel, etaplus,
} from '../../../assets/images';
import { LogosBox, Logo } from '../styles';

const LogosContainer = () => {
  const logos = [erontrans, greenyard, nagel, etaplus];

  return (
    <>
      <LogosBox>
        <Container>
          <Row justify="center">
            {logos.map(logo => (
              <Col lg={3} md={6} sm={12}>
                <Logo logo={logo} />
              </Col>
            ))}
          </Row>
        </Container>
      </LogosBox>
    </>
  );
};

export default LogosContainer;
