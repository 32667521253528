import React from 'react';
import { graphql, withPrefix } from 'gatsby';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-grid-system';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LogosContainer from '../components/LogosContainer';
import Button from '../components/common/components/Button';
import ContentContainer from '../components/common/components/ContentContainer';
import Link from '../components/common/components/Link';
import { homeBg } from '../assets/images';
import { H1 } from '../constants/fonts';
import breakpoints from '../constants/breakpoints';

const HomePageContainer = styled.div`
  position: relative;
  padding-top: 30vh;
  padding-bottom: 5vh;
  &::before {
    background-image: url(${homeBg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    position: absolute;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-top: 15vh;
    &::before {
      background-size: 58%;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-bottom: 30vh;
  }
`;

const Intro = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 1.2em;
    margin: 0.6em;
  }
`;

const FeaturesContainer = styled.div`
  padding: 5vh 0;
`;

const Features = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  img {
    flex: 0 0 auto;
    width: 80px;
    height: 80px;
    display: flex;
    text-align: center;
  }
`;

const FeaturesContent = styled.p`
  margin-bottom: 0;
  margin-top: 0.5em;
`;

const styledCol = {
  marginBottom: '2.8rem',
};

const Home = ({ data }) => {
  const json = data.allFeaturesJson.edges;
  return (
    <Layout>
      <SEO title="Stona Główna" />
      <HomePageContainer>
        <Intro>
          <Container>
            <Row align="start">
              <Col lg={6} sm={12}>
                <ContentContainer>
                  <H1>Poznaj nas</H1>
                  <p>
                    Oferujemy usługi przewozowe w zakresie transportu krajowego, zarówno pojazdami
                    do 3.5t DMC, jak i ciągnikami siodłowymi. Jesteśmy zainteresowani współpracą
                    zarówno z firmami dużymi, jak i małymi podmiotami gospodarczymi.
                  </p>
                  <Button link="/about" text="Więcej" />
                </ContentContainer>
              </Col>
            </Row>
          </Container>
        </Intro>
      </HomePageContainer>
      <FeaturesContainer>
        <Container>
          <Row justify="center">
            {json.map(edge => (
              <Col lg={4} md={12} sm={12} key={edge.node.id} style={styledCol}>
                <ContentContainer>
                  <Features>
                    {edge.node.icon && <img src={withPrefix(edge.node.icon)} alt="icon" />}
                    <Link link={edge.node?.link} text={edge.node?.title} href={edge.node?.title} />
                    {edge.node.list.map(item => (
                      <FeaturesContent>{item.item ? item.item : null}</FeaturesContent>
                    ))}
                  </Features>
                </ContentContainer>
              </Col>
            ))}
          </Row>
        </Container>
      </FeaturesContainer>
      <LogosContainer />
    </Layout>
  );
};

export const query = graphql`
  query {
    allFeaturesJson {
      edges {
        node {
          id
          title
          list {
            item
          }
          link
          icon
        }
      }
    }
  }
`;

export default Home;
