import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { H3 } from '../../../../constants/fonts';
import color from '../../../../constants/colors';

const StyledLink = styled(props => <GatsbyLink {...props} />)`
  letter-spacing: 0.025em;
  color: ${color.darkGrey};
  text-decoration: underline;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  &:hover {
    color: ${color.orange};
    -webkit-transform: translateX(-0.4rem);
    transform: translateX(-0.4rem);
  }
`;

const Link = ({ link, text }) => (link ? (
    <StyledLink to={link}>
      <H3>{text}</H3>
    </StyledLink>
) : (
    <H3>{text}</H3>
));

export default Link;
