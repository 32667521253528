import styled from 'styled-components';

const LogosBox = styled.div`
  opacity: 0.5;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  &:hover {
    opacity: 1;
    -webkit-transform: translateX(-0.4rem);
    transform: translateX(-0.4rem);
  }
`;

const Logo = styled.div`
  background-image: url(${props => props.logo});
  background-size: 100%;
  max-width: 18rem;
  height: 18rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
`;

export { LogosBox, Logo };
